<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false"></nav-form>
    <section>
      <article style="height:367px">
        <field
          name="delegacion_id"
          widget="m2o"
          searchable
          label="Delegación"
          placeholder
          width="130px"
          style="top:9px; left:5px;"
          readonly
          inputStyle="text-align:center;font-weight:bold;"
        />
        <field
          name="ndoc"
          widget="char"
          searchable
          label="Nº Liquid."
          placeholder
          width="67px"
          style="top:9px; left:140px;"
          :readonly="mode == 'edit'"
          inputStyle="text-align:center;font-weight:bold;"
        />
        <field
          name="fecha"
          type="date"
          label="F. Recepción"
          help="Fecha"
          required
          default="today"
          width="105px"
          style="top:9px; left:210px;"
          searchable
        />
        <field
          name="representante_id"
          widget="m2o"
          label="Representante"
          placeholder="Rep."
          required
          width="220px"
          style="top:8px; left:320px;"
          searchable
          :fields="[
            'comision_fija_liquidacion',
            'irpf',
            'iva',
            'penalizacion_forma_pago',
            { name: 'forma_pago_id', fields: ['descripcion'] }
          ]"
        />
        <field
          name="divisa_id"
          widget="m2o"
          searchable
          label="Liquidar en..."
          width="90px"
          style="top:9px; left:565px;"
          readonly
        />

        <r-tabs
          ref="main-tabs"
          :wt="formData.wt"
          style="top:55px; left:0px; height:310px;"
          :buttons="['Facturas', 'Pie']"
          :classButton="{ disabled: !formData.representante_id }"
        >
          <div class="tab">
            <field
              ref="lineas"
              name="lineas"
              class="lineas"
              :htSettings="facturas_customprop"
              widget="handsontable"
              :fields="[
                {
                  name: 'factura_cliente_id',
                  fields: [
                    { name: 'delegacion_id', fields: ['nombre'] },
                    'ndoc',
                    'fecha',
                    { name: 'cliente_id', fields: ['nombre_comercial'] },
                    'total',
                    { name: 'divisa_id', fields: ['nombre'] },
                    'cambio',
                    { name: 'formapago_id', fields: ['descripcion'] }
                  ]
                }
              ]"
              :height="256"
              :minRows="18"
              style="top:10px; left:4px; width: 648px;"
              customprop="facturas_customprop"
              readonly
              :columns="[
                {
                  name: 'factura_cliente_id.delegacion_id',
                  header: 'Del.',
                  type: 'm2o',
                  primary: 'codigo',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.ndoc',
                  header: 'NºDocumento',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.cliente_id',
                  header: 'Cliente',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre_comercial',
                  showCode: true
                },
                {
                  name: 'factura_cliente_id.fecha',
                  header: 'Fecha',
                  type: 'date2',
                  primary: 'codigo',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.total',
                  header: 'total',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.cambio',
                  header: 'Cambio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'factura_cliente_id.formapago_id',
                  header: 'F.pago',
                  readOnly: true,
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'descripcion'
                },
                {
                  name: 'calculo',
                  header: 'Cálculo',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'comision',
                  header: 'Comisión',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                { name: 'tipo_cobro', header: 'T.Cob.' },
                //penalizacion
                //cobrado
                {
                  name: 'porcentaje_cobrado',
                  header: '%Cob.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'liquidable',
                  header: 'Liquidable',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'liquidado',
                  header: 'Pagado',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'pendiente',
                  header: 'Pendiente',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  readOnly: true
                },
                {
                  name: 'liquidar',
                  header: 'Liquidar',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                },
                {
                  name: 'l',
                  header: 'Liquidado',
                  type: 'checkbox'
                }
              ]"
            />
          </div>
          <div class="tab">
            <field
              name="sunumerofactura"
              widget="char"
              label="Su Nº Factura"
              width="110px"
              style="top:10px; left:20px;"
            />
            <field
              name="fechasufactura"
              widget="date"
              type="date"
              label="Fecha de su Factura"
              width="110px"
              style="top:10px; left:150px;"
            />
            <fieldset
              style="position: relative; left: 10px; top: 50px; width: 250px; height:61px"
            >
              <legend>Tipo Nacionalidad</legend>
              <field
                name="nacionalidad"
                widget="radio"
                default="union_europea"
                :options="{
                  Nacional: 'nacional',
                  'Extracom.': 'extracomunitario',
                  UE: 'union_europea'
                }"
                inline-label
                style="top:23px; left:15px;"
              />
            </fieldset>
            <field
              name="observaciones"
              widget="text"
              label="Observaciones"
              width="250px"
              height="100px"
              style="top:135px; left:10px;"
            />
            <fieldset
              style="position: relative; left: 270px; top: -42px; width: 345px; height:240px"
            >
              <field
                name="importe_fijo"
                widget="float"
                type="number"
                dec="2"
                label="Importe fijo de comisión"
                inline-label
                width="67px"
                style="top:10px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="importe_calculado"
                widget="float"
                type="number"
                dec="2"
                label="Importe calculado de comisión"
                inline-label
                width="67px"
                style="top:38px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <hr style="position:absolute;top:60px;right:15px;width:250px" />
              <field
                name="base_imponible"
                widget="float"
                type="number"
                dec="2"
                label="Base imponible"
                inline-label
                width="67px"
                style="top:78px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="ivap"
                widget="float"
                type="number"
                dec="2"
                label="IVA"
                inline-label
                sufix="%"
                width="67px"
                style="top:106px; right:87px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="iva"
                widget="float"
                type="number"
                dec="2"
                width="67px"
                style="top:106px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="irpfp"
                widget="float"
                type="number"
                dec="2"
                label="IRPF"
                inline-label
                sufix="%"
                width="67px"
                style="top:134px; right:87px;"
                inputStyle="text-align:right;"
                readonly
              />
              <field
                name="irpf"
                widget="float"
                type="number"
                dec="2"
                width="67px"
                style="top:134px; right:15px;"
                inputStyle="text-align:right;"
                readonly
              />
              <hr style="position:absolute;top:160px;right:15px;width:250px" />
              <field
                name="total"
                widget="float"
                type="number"
                dec="2"
                width="150px"
                style="bottom:38px; right:15px;"
                readonly
                label="Total a pagar"
                inline-label
                inputStyle="text-align:right; font-weight:normal; font-size: 1.4em!important;"
              />
              <field
                name="formapago"
                widget="select"
                :options="{
                  Contado: 'contado',
                  Transferencia: 'transferencia',
                  Pagaré: 'pagare'
                }"
                inline-label
                label="Forma de pago"
                width="150px"
                style="right:15px; bottom:10px;"
              />
            </fieldset>
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
import CompraMixin from "./../components/CompraMixin.js";
import FacturaMixin from "./../components/FacturaMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function() {
    var self = this;
    return {
      title: "Liquidación de comisiones",
      dbAdapter: "liquidacion_comision",
      primary: "ndoc",
      defaultDataSearch: {
        wt: 0
      },
      defaultData: {
        wt: 0,
        fecha: new Date().yyyymmdd(),
        delegacion_id: self.app.config.delegacion_defecto || null,
        divisa_id: self.app.config.moneda_defecto || null
      },
      sequence: {
        name: "liquidacion_comision"
      },
      fields: ["wt"],
      facturas_customprop: {
        /*afterSelectionByProp: function(r, p, r2, p2) {
          var h = this;
          //var lineas = $.extend(true, [], h.getSourceData()[r].lineas);
          var lineas = h.getSourceData()[r].lineas || [{}];
          APP.WIDGETS.o2m.load(
            v.find('.field[data-name="lineas"]'),
            { lineas: lineas },
            "auto"
          );
          v.find('.field[data-name="lineas"]').data("factura_row", r);
        },*/
        beforeChange: function(changes, source) {
          if (!changes) return;
          var hf = this;
          window.$.each(changes, function(index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];
            if (prop == "tipo_cobro") {
              if (newVal == "i") changes[index][3] = "I";
              else if (newVal == "p") changes[index][3] = "P";
              else if (newVal == "c") changes[index][3] = "C";
              else if (window.$.inArray(newVal, ["I", "P", "C"]) == -1)
                changes[index][3] = "I";
            }
            if (prop == "liquidar") {
              var liquidar = parseFloat(newVal) || 0.0;
              var pendiente =
                parseFloat(hf.getSourceData()[row]["pendiente"]) || 0.0;
              if (liquidar > pendiente) {
                changes[index][3] = pendiente;
              }
              if (liquidar < 0) {
                changes[index][3] = 0.0;
              }
            }
          });
        },
        afterChange: function(changes, source) {
          if (!changes) return;
          //console.log(changes);
          var hf = this;
          window.$.each(changes, function(index, change) {
            var row = change[0];
            var prop = change[1];
            var oldVal = change[2];
            var newVal = change[3];

            var sel = parseInt(hf.getSourceData()[row]["sel"] || 0) || 0;
            var pendiente =
              parseFloat(hf.getSourceData()[row]["pendiente"]) || 0.0;
            var penalizacion =
              parseFloat(hf.getSourceData()[row]["penalizacion"]) || 0.0;

            if (prop == "cobrado") {
              var total = parseFloat(hf.getSourceData()[row]["total"]) || 0.0;
              var porcentaje_cobrado = (parseFloat(newVal) / total) * 100;
              if (!isFinite(porcentaje_cobrado)) porcentaje_cobrado = 0.0;
              hf.setDataAtRowProp(
                row,
                "porcentaje_cobrado",
                porcentaje_cobrado,
                "auto"
              );
            }
            if (prop == "calculo") {
              hf.setDataAtRowProp(
                row,
                "comision",
                parseFloat(newVal) - (penalizacion / 100) * parseFloat(newVal),
                "auto"
              ); //TODO: Restar penalización
            }
            if (prop == "comision" || prop == "tipo_cobro") {
              var comision =
                parseFloat(hf.getSourceData()[row]["comision"]) || 0.0;
              porcentaje_cobrado =
                parseFloat(hf.getSourceData()[row]["porcentaje_cobrado"]) ||
                0.0;
              switch (hf.getSourceData()[row]["tipo_cobro"]) {
                case "I":
                  hf.setDataAtRowProp(row, "liquidable", comision, "auto");
                  break;
                case "P":
                  hf.setDataAtRowProp(
                    row,
                    "liquidable",
                    (comision * porcentaje_cobrado) / 100,
                    "auto"
                  );
                  break;
                case "C":
                  hf.setDataAtRowProp(
                    row,
                    "liquidable",
                    comision * (porcentaje_cobrado == 100),
                    "auto"
                  );
                  break;
              }
            }
            if (prop == "liquidable") {
              var liquidado =
                parseFloat(hf.getSourceData()[row]["liquidado"]) || 0.0; //Liquidado
              hf.setDataAtRowProp(
                row,
                "pendiente",
                parseFloat(newVal) - liquidado,
                "auto"
              );
            }
            if (prop == "pendiente" || prop == "sel") {
              if (sel) {
                hf.setDataAtRowProp(row, "liquidar", pendiente, "auto");
              } else {
                hf.setDataAtRowProp(row, "liquidar", 0.0, "auto");
              }
            }
            if (prop == "liquidar" || prop == "sel") {
              self.calcularComisionTotal();
            }
          });
        }
      }
    };
  },
  methods: {
    onChange(arrProps, callback) {
      this.$on("change", function(prop, source) {
        if (this.mode != "new" && this.mode != "edit") return;
        if (!arrProps.includes(prop)) return;
        callback(source);
      });
    },
    calcularComisionTotal: function(win) {
      var self = this;
      var hf = self.$refs.lineas.field.hotInstance;
      var calculo = 0;
      window.$.each(hf.getSourceData(), function(i, item) {
        if (parseInt(item["sel"])) {
          calculo += parseFloat(item["liquidar"]);
        }
      });
      self.$set(self.formData, "importe_calculado", calculo);
      self.$emit("change", "importe_calculado", "auto");
    }
  },
  mounted() {
    var self = this;
    //setTimeout(function() {
    //self.actualizarTodo(true);
    //}, 1000);

    self.$refs["main-tabs"].$refs.toolbar.addEventListener("dblclick", function(
      e
    ) {
      if (self.app.session.mode == "a") return;
      if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
        if (self.mode != "edit" && self.mode != "new" && self.mode != "search")
          return;
        self.$set(self.formData, "wt", !parseInt(self.formData.wt) ? 1 : 0);
        self.$emit("change", "wt", "edit");
        if (self.mode == "search") self.setFilter(self.getFilter());
      }
    });

    self.onChange(["representante_id"], function(source) {
      var v = self.formData.representante_id;
      if (v) {
        self.$set(self.formData, "irpfp", v.irpf);
        self.$set(self.formData, "ivap", v.iva);
        self.$set(self.formData, "importe_fijo", v.comision_fija_liquidacion);
      } else {
        self.$set(self.formData, "irpfp", 0.0);
        self.$set(self.formData, "ivap", 0.0);
        self.$set(self.formData, "importe_fijo", 0.0);
      }
      self.$emit("change", "irpfp", "auto");
      self.$emit("change", "ivap", "auto");
      self.$emit("change", "importe_fijo", "auto");
    });

    self.onChange(["importe_calculado", "importe_fijo"], function(source) {
      self.$set(
        self.formData,
        "base_imponible",
        parseFloat(self.formData.importe_calculado + self.formData.importe_fijo)
      );
      self.$emit("change", "base_imponible", "auto");
    });
    self.onChange(["base_imponible", "ivap"], function(source) {
      self.$set(
        self.formData,
        "iva",
        (parseFloat(self.formData.base_imponible) *
          parseFloat(self.formData.ivap)) /
          100
      );
      self.$emit("change", "iva", "auto");
    });
    self.onChange(["base_imponible", "irpfp"], function(source) {
      self.$set(
        self.formData,
        "irpf",
        (parseFloat(self.formData.base_imponible) *
          parseFloat(self.formData.irpfp)) /
          100
      );
      self.$emit("change", "irpf", "auto");
    });
    self.onChange(["base_imponible", "iva", "irpf"], function(source) {
      self.$set(
        self.formData,
        "total",
        parseFloat(self.formData.base_imponible) +
          parseFloat(self.formData.iva) -
          parseFloat(self.formData.irpf)
      );
      self.$emit("change", "total", "auto");
    });
  }
};
</script>